import { Component, OnInit } from '@angular/core';
import { KrpanoService } from '../krpano.service';
import { ConfigService } from '../config.service';
import { ActivatedRoute } from '@angular/router';
import { ImagesPreloaderService } from '../images-preloader.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-krpano-view',
  templateUrl: './krpano-view.component.html',
  styleUrls: ['./krpano-view.component.scss']
})
export class KrpanoViewComponent implements OnInit {
  constructor(
    private krpano: KrpanoService,
    private config: ConfigService,
    private route: ActivatedRoute,
    private imagePreloader: ImagesPreloaderService,
    private title: Title
  ) {
    this.route.params.subscribe(params => {
      this.krpano.roomId = params.roomId;
    });
  }

  async ngOnInit() {
    this.title.setTitle(this.config.config.project_name);
    await this.imagePreloader.load();
    await this.krpano.init();
    if (this.krpano.roomId) {
      this.krpano.goToRoom(this.krpano.rooms[this.krpano.roomId]);
    }
  }
}
