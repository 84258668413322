import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RoomSelectService {
  opened = false;

  constructor() { }
}
