import { Component, OnInit } from '@angular/core';
import { ConfigService, Item } from '../config.service';
import { KrpanoService } from '../krpano.service';

@Component({
  selector: 'app-edit-hotspot',
  templateUrl: './edit-hotspot.component.html',
  styleUrls: ['./edit-hotspot.component.scss']
})
export class EditHotspotComponent implements OnInit {
  public exported = false;
  constructor(private config: ConfigService, private krpano: KrpanoService) {}

  addHotspot(item: Item) {
    this.krpano.waitForClickNewHotspotItem = item;
  }

  setStartAngle() {
    this.krpano.activeRoom.startLookAt = this.krpano.getViewAngle();
  }

  exportConfig() {
    this.config.exportConfig();
    this.exported = true;
  }

  ngOnInit() {}
}
