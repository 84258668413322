import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ConfigService } from './config.service';
import { KrpanoViewComponent } from './krpano-view/krpano-view.component';
import { IndexComponent } from './index/index.component';
import { RoomSelectComponent } from './room-select/room-select.component';
import { MaterialSelectComponent } from './material-select/material-select.component';
import { EditHotspotComponent } from './edit-hotspot/edit-hotspot.component';
import { LoadingComponent } from './loading/loading.component';
import { HomeComponent } from './home/home.component';
import { CartComponent } from './cart/cart.component';
import { FormsModule } from '@angular/forms';

export function configurationInit(config: ConfigService) {
  console.log('~> ', config);
  return () => config.init();
}

@NgModule({
  declarations: [
    AppComponent,
    KrpanoViewComponent,
    IndexComponent,
    RoomSelectComponent,
    MaterialSelectComponent,
    EditHotspotComponent,
    LoadingComponent,
    HomeComponent,
    CartComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: configurationInit,
      deps: [ConfigService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
