import { Component, OnInit, Input } from '@angular/core';
import { KrpanoService } from '../krpano.service';
import { ImagesPreloaderService } from '../images-preloader.service';
import { ConfigService, Type, Room } from '../config.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  selectedType: Type;
  roomList: Room[] = [];
  selectedRoom: Room;
  totalSurface = 0;

  constructor(public config: ConfigService, public krpano: KrpanoService, public imageLoader: ImagesPreloaderService) {}

  ngOnInit() {
    this.setType(this.config.config.types[1]);
    this.selectedRoom = this.roomList[1];
    this.setTotalSurface();
  }

  changeSurface(room: Room, surface: any) {
    room.surface = parseFloat(surface);
    this.setTotalSurface();
  }

  changeRoom(room: Room) {}

  submit() {
    this.config.config.rooms = this.roomList;
    this.krpano.goToRoom(this.krpano.rooms[this.config.config.default_room]);
  }

  private setTotalSurface() {
    this.totalSurface = 0;
    this.roomList.forEach(room => {
      this.totalSurface += room.surface;
    });
  }

  private getRoomListFromStrings(strs: string[]) {
    return strs.map(str => this.config.config.rooms.find(room => room.folder === str));
  }

  private setType(type: Type) {
    this.selectedType = type;
    this.roomList = this.getRoomListFromStrings(type.rooms);
    this.setTotalSurface();
  }
}
