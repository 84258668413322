import { Injectable } from '@angular/core';
import { Cube } from './krpano.service';
export interface Config {
  rooms: Room[];
  edit_enabled: boolean;
  default_room: string;
  project_name: string;
  types: Type[];
}

export interface Room {
  name: string;
  folder: string;
  items: Item[];
  backgroundCube?: Cube;
  cubeList: Cube[];
  thumb: string;
  hotspots: Hotspot[];
  surface: number;
  startLookAt: {
    h: number;
    v: number;
  };
}

export interface Type {
  name: string;
  rooms: string[];
}
export interface Hotspot {
  x: number;
  y: number;
}
export interface Item {
  name: string;
  type: ItemType;
  room: Room;
  folder: string;
  ranges: Range[];
  hotspots: Hotspot[];
  hotspotDisplayed: number;
  selectedMaterial: Material;
}

export enum ItemType {
  ITEM = 'item',
  SURFACE = 'surface'
}

export interface Range {
  name: string;
  folder: string;
  materials: Material[];
  thumb: string;
  border: string;
}

export interface Material {
  name: string;
  folder: string;
  price: number;
  cube?: Cube;
  fromItem: Item;
  thumb: string;
  active: boolean;
  default?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  config: Config;
  originalConfig: Config;
  newHotspots: Hotspot[] = [];
  constructor() {}

  /**
   * Fetch config file using raw XHR to avoid side effect
   * https://sebastienollivier.fr/blog/angular/pourquoi-il-ne-faut-pas-utiliser-httpclient-dans-appinitializer
   */
  private loadXHR(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', url);
      xhr.addEventListener('readystatechange', () => {
        if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
          resolve(xhr.responseText);
        } else if (xhr.readyState === XMLHttpRequest.DONE) {
          reject();
        }
      });
      xhr.send(null);
    });
  }

  exportConfig() {
    const conf = JSON.parse(JSON.stringify(this.originalConfig));
    this.config.rooms.forEach((room, roomIndex) => {
      conf.rooms[roomIndex].startLookAt = room.startLookAt;
      room.items.forEach((item, itemIndex) => {
        const originalItem = conf.rooms[roomIndex].items[itemIndex];
        originalItem.hotspots = originalItem.hotspots ? originalItem.hotspots : [];
        item.hotspots.forEach(hotspot => {
          if (this.newHotspots.indexOf(hotspot) !== -1) {
            originalItem.hotspots.push(hotspot);
          }
        });
      });
    });
    console.log('~> ', JSON.stringify(conf, null, 2));
  }

  async init() {
    const res = await this.loadXHR('assets/config.json');

    // meant to be modified
    this.config = JSON.parse(res);
    // meant to be as original to add hotspots
    this.originalConfig = JSON.parse(res);
    console.log('~> ', this.config);
  }
}
