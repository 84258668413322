import { Injectable } from '@angular/core';
import { RoomSelectService } from '../room-select/room-select.service';

@Injectable({
  providedIn: 'root'
})
export class MaterialSelectService {
  public opened = false;
  constructor(private roomSelect: RoomSelectService) {}

  openMenu() {
    this.opened = true;
    this.roomSelect.opened = false;
  }

  closeMenu() {
    this.opened = false;
  }
}
