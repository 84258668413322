import { Component, OnInit } from '@angular/core';
import { ConfigService, Range, Material, ItemType } from '../config.service';
import { KrpanoService } from '../krpano.service';
import { MaterialSelectService } from './material-select.service';

@Component({
  selector: 'app-material-select',
  templateUrl: './material-select.component.html',
  styleUrls: ['./material-select.component.scss']
})
export class MaterialSelectComponent implements OnInit {
  itemType = ItemType;

  constructor(private config: ConfigService, private krpano: KrpanoService, private mat: MaterialSelectService) {}

  selectMaterial(material: Material) {
    this.krpano.selectMaterial(material);
  }

  selectRange(range: Range) {
    if (range.materials.length === 0) return;

    this.krpano.selectedRange = range;
  }

  ngOnInit() {}
}
