import { Injectable } from '@angular/core';
import { ConfigService, Room, Material, Item, Hotspot, Range } from './config.service';
import { Location } from '@angular/common';
import { MaterialSelectService } from './material-select/material-select.service';
import { RoomSelectService } from './room-select/room-select.service';
import { ImagesPreloaderService } from './images-preloader.service';

declare var embedpano: any;

// nbr frame a material take to appear
const NBR_FRAME_DISPLAY = 15;
export interface Cube {
  name: string;
  path: string;
  displayed: boolean;
  room: Room;
}

@Injectable({
  providedIn: 'root'
})
export class KrpanoService {
  krpanoJs: any;
  activeRoom: Room;
  roomId: string;
  rooms: { [key: string]: Room } = {};
  // if a new hotspot is ready to be placed
  waitForClickNewHotspotItem: Item;
  selectedItem: Item;
  selectedRange: Range;
  totalPrice = 0;
  cubeSteps = [{ id: 0, ath: 90, atv: 0 }, { id: 1, ath: -90, atv: 0 }, { id: 2, ath: 0, atv: -90 }, { id: 3, ath: 0, atv: 90 }, { id: 4, ath: 0, atv: 0 }, { id: 5, ath: -180, atv: 0 }];
  ready = false;

  constructor(private config: ConfigService, private location: Location, private matSelect: MaterialSelectService, private roomSelect: RoomSelectService) {}

  goToRoom(room: Room) {
    if (this.activeRoom !== room) {
      this.selectedRange = undefined;
      this.selectedItem = undefined;
      this.matSelect.closeMenu();
      this.showCube(room.backgroundCube);
      room.cubeList.forEach(cube => (cube.displayed ? this.showCube : false));
      this.setViewAngle(room.startLookAt);
      for (const tmpRoom of this.config.config.rooms) {
        for (const item of tmpRoom.items) {
          if (tmpRoom !== room) {
            this.hideHotspots(item);
          } else {
            this.showHotspot(item);
            for (const range of item.ranges) {
              for (const material of range.materials) {
                if (material.active) {
                  this.showCube(material.cube);
                }
              }
            }
          }
        }
      }
      if (this.activeRoom) {
        this.hideCube(this.activeRoom.backgroundCube);
        for (const cube of this.activeRoom.cubeList) {
          this.setCubeAlpha(cube, 0);
        }
      }
      // this.location.go(`/room/${room.folder}`);
      this.activeRoom = room;
    }
  }

  setHotspotAlpha(name: string, alpha: number) {
    this.krpanoJs.call(`set(hotspot[${name}].alpha, ${alpha})`);
  }

  showHotspot(item: Item) {
    item.hotspots.forEach((hotspot, index) => {
      this.setHotspotAlpha(`${item.room.folder}_${item.folder}_${index + 1}`, 1);
    });
  }

  hideHotspots(item: Item) {
    item.hotspots.forEach((hotspot, index) => {
      this.setHotspotAlpha(`${item.room.folder}_${item.folder}_${index + 1}`, 0);
    });
  }

  updateTotalPrice() {
    this.totalPrice = 0;
    for (const room of this.config.config.rooms) {
      if (room.items) {
        for (const item of room.items) {
          if (item.selectedMaterial && item.selectedMaterial.price) {
            this.totalPrice += item.selectedMaterial.price * room.surface;
          }
        }
      }
    }
  }

  async selectMaterial(material: Material) {
    const promises: Promise<void>[] = [];
    material.fromItem.selectedMaterial = material;
    if (material.cube.displayed === false) {
      for (const range of material.fromItem.ranges) {
        for (const otherMaterial of range.materials) {
          if (otherMaterial.cube.displayed) {
            promises.push(this.toggleCubeSlowly(otherMaterial.cube));
          }
        }
      }
      promises.push(this.toggleCubeSlowly(material.cube));
      for (const mat of this.selectedRange.materials) {
        mat.active = false;
      }
      material.active = true;
      this.updateTotalPrice();
      await Promise.all(promises);
    }
  }

  /**
   * Load config and improve config object
   */
  async initConfig() {
    for (const room of this.config.config.rooms) {
      this.rooms[room.folder] = room;
      room.backgroundCube = {
        displayed: false,
        name: `${room.folder}`,
        path: `assets/repository/${room.folder}/background`,
        room
      };
      room.cubeList = [];
      room.hotspots = [];
      this.rooms[room.folder] = room;
      this.loadCube(room.backgroundCube);

      for (const item of room.items) {
        for (const range of item.ranges) {
          if (!item.selectedMaterial && range.materials.length > 0) {
            item.selectedMaterial = range.materials.find(mat => mat.default);
          }
        }
        item.hotspotDisplayed = 0;
        item.room = room;

        for (const range of item.ranges) {
          item.hotspots = item.hotspots ? item.hotspots : [];
          item.hotspots.forEach(hotspot => room.hotspots.push(hotspot));
          for (const material of range.materials) {
            material.fromItem = item;

            if (material.folder) {
              material.cube = {
                displayed: false,
                name: `${room.folder}_${item.folder}_${range.folder}_${material.folder}`,
                path: `assets/repository/${room.folder}/${item.folder}/${range.folder}/${material.folder}`,
                room
              };

              this.loadCube(material.cube);
              room.cubeList.push(material.cube);
            } else {
              material.cube = {
                displayed: false,
                name: `${room.folder}_${item.folder}_${range.folder}_${material.name}`,
                path: '',
                room
              };
            }
          }
        }

        if (item.hotspots) {
          for (const hotspot of item.hotspots) {
            this.addHotspot(item, hotspot);
          }
        }
      }
    }
    this.updateTotalPrice();
  }

  onHotSpotClick(item: Item) {
    this.matSelect.openMenu();
    this.selectedRange = undefined;
    this.selectedItem = item;
  }

  /**
   * Loads Hotspot in krpano
   */
  createKrpanoHotspot(name: string, imagePath: string, ath: number, atv: number) {
    [
      `addhotspot(${name})`,
      `set(hotspot[${name}].url, ${imagePath})`,
      `set(hotspot[${name}].ath, ${ath})`,
      `set(hotspot[${name}].atv, ${atv})`,
      `set(hotspot[${name}].onclick, jscall(
        var event = document.createEvent('Event');
        event.initEvent('${name}_clicked', true, true);
        document.dispatchEvent(event);
      ));`
    ].forEach(item => this.krpanoJs.call(item));
  }

  setViewAngle(angle: { h: number; v: number }) {
    this.krpanoJs.call('lookat(' + angle.h + ',' + angle.v + ')');
  }

  getViewAngle() {
    return {
      h: this.krpanoJs.get('view.hlookat'),
      v: this.krpanoJs.get('view.vlookat')
    };
  }

  /**
   * Load cube in krpano
   */
  loadCube(cube: Cube) {
    for (const step of this.cubeSteps) {
      this.createKrpanoHotspot(`${cube.name}_${step.id}`, `${cube.path}/${step.id}.png`, step.ath, step.atv);
      this.krpanoJs.call(`hotspot[${cube.name}_${step.id}].loadstyle(cubeface)`);
    }
  }

  setCubeAlpha(cube: Cube, alpha: number) {
    for (const step of this.cubeSteps) {
      this.krpanoJs.call(`set(hotspot[${cube.name}_${step.id}].alpha, ${alpha});`);
    }
  }

  showCube(cube: Cube) {
    this.setCubeAlpha(cube, 1);
    cube.displayed = true;
  }

  hideCube(cube: Cube) {
    this.setCubeAlpha(cube, 0);
    cube.displayed = false;
  }

  toggleCubeView(cube: Cube) {
    this.setCubeAlpha(cube, cube.displayed ? 0 : 1);
    cube.displayed = !cube.displayed;
  }

  /**
   * make hotspot appear or disapear smoothly
   */
  async toggleCubeSlowly(cube: Cube) {
    let j = cube.displayed ? NBR_FRAME_DISPLAY : 0;
    for (let i = 0; i < NBR_FRAME_DISPLAY; i++) {
      for (const step of this.cubeSteps) {
        this.krpanoJs.call(`set(hotspot[${cube.name}_${step.id}].alpha, ${j / NBR_FRAME_DISPLAY})`);
      }
      await new Promise(resolve => window.requestAnimationFrame(resolve));
      j = cube.displayed ? j - 1 : j + 1;
    }
    cube.displayed = !cube.displayed;
  }

  addHotspot(item: Item, hotspot: Hotspot) {
    const name = `${item.room.folder}_${item.folder}_${item.hotspotDisplayed + 1}`;
    this.createKrpanoHotspot(name, `assets/img/selectMaterial.png`, hotspot.x, hotspot.y);
    this.krpanoJs.call(`hotspot[${name}].loadstyle(select-material)`);
    document.addEventListener(
      `${name}_clicked`,
      () => {
        this.onHotSpotClick(item);
      },
      false
    );
    item.room.hotspots.push(hotspot);
    item.hotspotDisplayed++;
  }

  onClickOnBackground() {
    // Close menus on background click
    this.matSelect.closeMenu();
    this.roomSelect.opened = false;

    // Hotspot Editor
    if (this.config.config.edit_enabled && this.waitForClickNewHotspotItem) {
      const mouseX = this.krpanoJs.get('mouse.x');
      const mouseY = this.krpanoJs.get('mouse.y');
      const pos = this.krpanoJs.screentosphere(mouseX, mouseY);
      const hotspot = {
        x: pos.x,
        y: pos.y
      };
      this.waitForClickNewHotspotItem.hotspots.push(hotspot);
      this.addHotspot(this.waitForClickNewHotspotItem, hotspot);
      this.config.newHotspots.push(hotspot);
      this.waitForClickNewHotspotItem = undefined;
    }
  }

  async init() {
    this.krpanoJs = await new Promise(resolve => {
      embedpano({
        xml: 'assets/repository/main.xml',
        target: 'krpano-container',
        html5: 'auto',
        mobilescale: 1.0,
        passQueryParameters: true,
        onready: resolve
      });
    });
    this.initConfig();
    this.ready = true;

    // Krpano backgroud click event seted in raw xml
    document.addEventListener(`mouseClicked`, this.onClickOnBackground.bind(this), false);
  }
}
