import { Component, OnInit } from '@angular/core';
import { ConfigService, Room, ItemType } from '../config.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {
  opened = false;
  rooms: Room[];
  itemType = ItemType;
  totalPrice = 0;

  constructor(private config: ConfigService) {
    this.rooms = config.config.rooms.filter(room => room.items.some(item => typeof item.selectedMaterial !== 'undefined'));

    for (const room of this.rooms) {
      const selectedItems = room.items.filter(item => item.selectedMaterial);
      for (const item of selectedItems) {
        if (item.type === ItemType.SURFACE) {
          this.totalPrice += item.selectedMaterial.price * room.surface;
        } else {
          this.totalPrice += item.selectedMaterial.price;
        }
      }
    }
  }

  ngOnInit() {}

  toggleView() {
    this.opened = !this.opened;
  }

  buy() {}
}
