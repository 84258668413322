import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class ImagesPreloaderService {
  loaded = 0;
  images: string[] = [];
  constructor(private config: ConfigService) {
    const pngs = ['0.png', '1.png', '2.png', '3.png', '4.png', '5.png'];
    for (const room of this.config.config.rooms) {
      for (const png of pngs) {
        this.images.push(`assets/repository/${room.folder}/background/${png}`);
      }
      for (const item of room.items) {
        for (const range of item.ranges) {
          for (const material of range.materials) {
            if (material.folder) {
              for (const png of pngs) {
                this.images.push(`assets/repository/${room.folder}/${item.folder}/${range.folder}/${material.folder}/${png}`);
              }
            }
          }
        }
      }
    }
  }

  async loadImage(path: string) {
    const tmp = new Image();
    tmp.src = path;
    await new Promise(resolve => {
      tmp.onload = resolve as any;
    });
  }

  async load() {
    let i = 0;
    for (const image of this.images) {
      await this.loadImage(image);
      i++;
      this.loaded = (i / this.images.length) * 100;
    }
  }
}
