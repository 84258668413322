import { Component, OnInit } from '@angular/core';
import { ImagesPreloaderService } from '../images-preloader.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {
  constructor(public imagePreloader: ImagesPreloaderService) {}

  ngOnInit() {}
}
