import { Component, OnInit } from '@angular/core';
import { KrpanoService } from '../krpano.service';
import { ConfigService } from '../config.service';
import { RoomSelectService } from './room-select.service';
import { MaterialSelectService } from '../material-select/material-select.service';

@Component({
  selector: 'app-room-select',
  templateUrl: './room-select.component.html',
  styleUrls: ['./room-select.component.scss']
})
export class RoomSelectComponent implements OnInit {
  constructor(
    private krpano: KrpanoService,
    private config: ConfigService,
    private roomSelect: RoomSelectService,
    private materialSelect: MaterialSelectService
  ) {}

  toggleView() {
    this.roomSelect.opened = !this.roomSelect.opened;
    this.materialSelect.opened = this.roomSelect.opened
      ? false
      : this.materialSelect.opened;
  }

  ngOnInit() {}
}
